// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-samples-tsx": () => import("./../../../src/templates/samples.tsx" /* webpackChunkName: "component---src-templates-samples-tsx" */),
  "component---src-templates-standard-tsx": () => import("./../../../src/templates/standard.tsx" /* webpackChunkName: "component---src-templates-standard-tsx" */),
  "component---src-templates-start-tsx": () => import("./../../../src/templates/start.tsx" /* webpackChunkName: "component---src-templates-start-tsx" */)
}

